<template>
  <section>
    <div
      class="doctor-item"
      v-for="(item, index) in list"
      :key="index"
      :index="index"
    >
      <div class="doctor-img">
        <img
          src="@/assets/img/yisheng.png"
          v-if="item.portraitUrl == null || item.portraitUrl == ''"
          class="doctor-img"
          alt=""
          @click="doctorPage(item)"
        />
        <img
          :src="item.portraitUrl"
          v-else
          class="doctor-img"
          alt=""
          @click="doctorPage(item)"
        />
      </div>
      <div class="doctor-info">
        <div class="doctor-base-info">
          <span class="doctor-name" @click="doctorPage(item)">{{
            item.doctorName
          }}</span>
          <span class="doctor-deptName" @click="doctorPage(item)">{{
            item.doctorTitle
          }}</span>
          <span class="collect"> 收藏 </span>
          <i
            class="iconfont icon-xinaixin"
            :class="item.concern == false ? 'disactive' : 'active'"
            @click="toCollect(item)"
          ></i>
          <div class="doctor-dept" @click="doctorPage(item)">
            {{ item.deptName }}
          </div>
        </div>
        <div class="doctor-profile" @click="doctorPage(item)">
          简介：<span>{{
            item.jianJie == null ? "暂无简介" : item.jianJie
          }}</span>
        </div>
      </div>
    </div>
    <div class="dept-info">
      <div class="dept_title">科室简介</div>
      <p>暂无科室简介</p>
      <!-- <p>
          皮肤性病科是我院集诊疗、教学、科研为一体、技术力量雄厚的特色专科。设有门诊及病房，门诊包括专家门诊、普通门诊、皮肤外科门诊；治疗室三间，包括：普通治疗室、美容治疗室和性病治疗室。目前科室拥有主任医师1人；副主任医师1人；主治医师2人；其中硕士研究生2人，医生护士共10余人。科室开放床位10张，独立门诊200平方米。门诊就诊年平均40,000人次，住院病人近200人次。设有：手术室、真菌室、变应原测定室。拥有：德国Waldmann紫外光治疗仪；英国Omnilux红蓝光痤疮治疗仪；德国Mediwiss变应原测定系统及日本Olympus
          显微镜及白癜风表皮移植治疗机等设备。
        </p>
        <p>
          科室目前开展顽固性皮炎、湿疹、荨麻疹、带状疱疹、痤疮、色素病（如：黄褐斑、白癜风），真菌性皮肤病，红斑鳞屑性皮肤病（如：银屑病、玫瑰糠疹），感染性皮肤病，体表肿瘤等常见病多发病外，依托皮肤病理开展疑难皮肤病诊治。
        </p>
        <p>
          我们以“亲民爱民，艰苦奋斗，科学求实，迎难而上，无私奉献”的焦裕禄精神作为科室文化，认真学习，努力拼搏，以“三好一满意”为目标，创优质服务品牌。
        </p> -->
    </div>
  </section>
</template>

<script>
import { Notify } from "vant";
export default {
  name: "deptPage",
  data() {
    return {
      title: this.$route.query.title,
      currentSort: true,
      list: [],
    };
  },
  methods: {
    doctorPage(item) {
      sessionStorage.setItem("currentDoctor", JSON.stringify(item));
      this.$router.push({
        path: "/doctorPage",
      });
    },
    async toCollect(item) {
      let postData = {
        userid: sessionStorage.getItem("openid"),
        doctorId: item.doctorCode,
        deptCode: item.deptCode,
      };
      if (!item.concern) {
        // 收藏
        await this.$http.post("/api/favorite/add", postData);
        Notify({
          message: "已收藏",
          color: "#fff",
          background: "#00D48B",
        });
      } else {
        // 取消收藏
        await this.$http.put("/api/favorite/cancle", postData);
        Notify({
          message: "取消收藏",
          color: "#fff",
          background: "#C6C6C6",
        });
      }
      item.concern = !item.concern;
      this.getDortor();
    },
    getDortor() {
      let postData = {
        userid: sessionStorage.getItem("openid"),
        code: this.$route.query.code,
      };
      this.$http.get("/api/dept/doctor", postData).then((res) => {
        if (res.status == 200) {
          this.list = res.data;
        }
      });
    },
  },
  mounted() {
    this.getDortor();
  },
};
</script>

<style scoped>
@import url(//at.alicdn.com/t/font_2783444_ka1zwfgai2j.css);
.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
.active {
  color: #00d48b;
}
.doctor-item {
  width: 9rem;
  height: 3.2rem;
  background-color: #fff;
  margin: auto;
  margin-top: 0.4rem;
  border-radius: 15px;
  -webkit-box-shadow: #e6e6e6 0px 0px 10px;
  -moz-box-shadow: #e6e6e6 0px 0px 10px;
  box-shadow: #e6e6e6 0px 0px 10px;
}
.doctor-img {
  width: 2rem;
  float: left;
}
.doctor-img img {
  height: 1.5rem;
  width: 1.5rem;
  margin-left: 5px;
  margin-top: 10px;
  border-radius: 50%;
}
.doctor-info {
  width: 7rem;
  height: inherit;
  float: left;
}
.doctor-base-info {
  width: 7rem;
  height: 1.4rem;
  text-align: left;
  border-bottom: 1px solid #e6e6e6;
  font-size: 0.4rem;
  margin-top: 20px;
}
.doctor-dept {
  text-align: left;
  margin-top: 0.1rem;
}
.doctor-name {
  color: #333333;
  padding-right: 15px;
  font-weight: bold;
}
.doctor-deptName {
  color: #333333;
}
.collect {
  float: right;
  font-size: 0.4rem;
  padding-right: 15px;
  color: #a4a5ab;
}
.icon-xinaixin {
  float: right;
  font-size: 0.45rem !important;
  padding-top: 2px;
  padding-right: 3px;
}
.active {
  color: #00d48b !important;
}
.disactive {
  color: #c6c6c6 !important;
}
.doctor-profile {
  font-size: 0.4rem;
  margin-top: 0.3rem;
  text-align: left;
  width: 7rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.dept-info {
  font-size: 0.4rem;
  padding: 0.3rem 0.5rem;
  text-align: left;
  text-indent: 0.7rem;
}

.dept-info p {
  line-height: 0.8rem;
  color: rgba(153, 153, 153, 1);
}

.dept_title {
  font-size: 0.5rem;
  font-weight: bold;
  text-align: left;
  margin-bottom: 0.3rem;
  text-indent: 0px;
}

.dept_title:before {
  content: "";
  display: inline-block;
  width: 0.5rem;
  height: 0.5rem;
  margin: 0 10px;
  border-radius: 2px;
  background-image: url("../../../assets/img/guide/team/deptProfile.png");
  background-size: contain;
  position: relative;
  top: 3px;
}
</style>
